import BreadcrumbItem from '@/components/layout/Breadcrumbs/BreadcrumbItem';
import { pageMetadataAtom } from '@/components/PageMetadata';
import Module from '@/types/Module';
import { useAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '@/types/Breadcrumbs';

const Breadcrumbs = () => {
    const [pageMetadata] = useAtom(pageMetadataAtom);
    const router = useRouter();
    const [breadcrumbs, setBreadcrumbs] = useState<Array<Breadcrumb> | null>(null);

    useEffect(() => {
        if (router) {
            const linkPath = router.asPath.split('/');
            linkPath.shift();

            const pathArray = linkPath.map((path, i) => {
                if (pageMetadata.title !== undefined && i === linkPath.length - 1) {
                    return {
                        title: pageMetadata.title,
                        href: '/' + linkPath.slice(0, i + 1).join('/'),
                    };
                }

                return {
                    title: path,
                    href: '/' + linkPath.slice(0, i + 1).join('/'),
                };
            });

            setBreadcrumbs(pathArray);
        }
    }, [router, pageMetadata]);

    if (pageMetadata.module === Module.NONE) {
        return null;
    }

    if (!breadcrumbs) {
        return null;
    }

    return (
        <nav aria-label="breadcrumbs" className="mb-2 ml-4 hidden sm:block">
            <ol className="text-sm text-gray-600">
                <li className="inline hover:underline">
                    <Link href="/">Dashboard</Link>
                </li>
                {breadcrumbs.map((breadcrumb, i) => {
                    return (
                        <BreadcrumbItem
                            key={breadcrumb.href}
                            breadcrumb={breadcrumb.title}
                            href={breadcrumb.href}
                            index={i}
                            numberOfBreadcrumbs={breadcrumbs.length}
                        />
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
