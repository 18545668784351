import { useGetVersion } from '@/queries/GetVersion';
import versionResource from '@/resources/version.json';
import React from 'react';

const Version = () => {
    const { data } = useGetVersion();

    if (!data) {
        return <div>UI: {versionResource.version}</div>;
    }

    return (
        <div>
            UI: {versionResource.version} | API: {data.content}
        </div>
    );
};

export default Version;
