import CoreLayout from '@/components/layout/CoreLayout';
import RequireAuthentication from '@/components/security/RequireAuthentication';
import RequireValidUser from '@/components/security/RequireValidUser';
import environment from '@/resources/environment.json';
import { Auth0Provider } from '@auth0/auth0-react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Provider as JotaiProvider } from 'jotai';
import type { AppProps } from 'next/app';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '../../styles/globals.css';
import { SHORT_CACHE } from '@/queries/QueryConstants';

config.autoAddCss = false;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: SHORT_CACHE.CACHE,
            staleTime: SHORT_CACHE.STALE,
        },
    },
});

const PageEntryPoint = ({ Component, pageProps }: AppProps) => {
    return (
        <Auth0Provider
            domain="helixbiostructures.auth0.com"
            clientId="20NTLeGZC0KBK9hj7oYncGWBYjjMx9BA"
            authorizationParams={{
                redirect_uri: environment.redirectURI,
            }}
        >
            <RequireAuthentication>
                <JotaiProvider>
                    <QueryClientProvider client={queryClient}>
                        <RequireValidUser>
                            <CoreLayout>
                                <Component {...pageProps} />
                            </CoreLayout>
                        </RequireValidUser>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </JotaiProvider>
            </RequireAuthentication>
        </Auth0Provider>
    );
};

export default PageEntryPoint;
