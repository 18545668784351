import { ResponseUserSummary, UserControllerApiFactory } from '@/api/api';
import { BASE_PATH } from '@/api/base';
import { Configuration } from '@/api/configuration';
import { axiosBuilder } from '@/queries/AxiosBuilder';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

export const useCurrentUser = () => {
    const { getIdTokenClaims } = useAuth0();

    const fetchCurrentUserData = async (): Promise<ResponseUserSummary> => {
        const axios = await axiosBuilder(getIdTokenClaims);

        return await UserControllerApiFactory(new Configuration(), BASE_PATH, axios)
            .getCurrentUser()
            .then((response) => response.data);
    };

    return useQuery<ResponseUserSummary, Error>(['user-getCurrentUser'], fetchCurrentUserData, {
        cacheTime: 10 * 60 * 1000 /* 10 minutes */,
        staleTime: 30 * 1000 /* 30 seconds */,
    });
};
