import { BASE_PATH } from '@/api/base';
import { IdToken } from '@auth0/auth0-react';
import axios, { AxiosInstance } from 'axios';

export const axiosBuilder = async (
    getIdTokenClaims: () => Promise<IdToken | undefined>
): Promise<AxiosInstance> => {
    const tokenClaims: IdToken | undefined = await getIdTokenClaims();
    if (tokenClaims === undefined) {
        throw new Error('Cannot get users IdToken claims for API call.');
    }

    return axios.create({
        baseURL: BASE_PATH,
        headers: {
            authorization: `Bearer ${tokenClaims['__raw']}`,
        },
        responseType: 'json',
    });
};
