import Link from 'next/link';
import React from 'react';

const Logo = () => {
    return (
        <Link href="/">
            <img
                alt="Helix Biostructures"
                src="https://assets.helixbiostructures-portal-dev.com/images/HlxLogo24.png"
                width={24}
                height={24}
            />
        </Link>
    );
};

export default Logo;
