import { currentUserAtom } from '@/components/security/CurrentUser';
import ValidatingCredentials from '@/components/security/ValidatingCredentials';
import { useCurrentUser } from '@/queries/GetCurrentUser';
import { useAuth0 } from '@auth0/auth0-react';
import { useAtom } from 'jotai';
import React from 'react';
import environment from '@/resources/environment.json';

interface RequireValidUserProps {
    children: React.ReactNode;
}

const RequireValidUser = (props: RequireValidUserProps) => {
    const [, setCurrentUser] = useAtom(currentUserAtom);
    const { data, isLoading, error } = useCurrentUser();
    const { logout } = useAuth0();

    if (error) {
        logout({ logoutParams: { returnTo: environment.redirectURI }})
    }

    if (isLoading) {
        return <ValidatingCredentials />;
    }

    if (data) {
        setCurrentUser(data.content);
    }

    return <>{props.children}</>;
};

export default RequireValidUser;
