import Breadcrumbs from '@/components/layout/Breadcrumbs/Breadcrumbs';
import CoreHead from '@/components/layout/CoreHead';
import Footer from '@/components/layout/Footer';
import NavigationBar from '@/components/layout/NavigationBar';
import React from 'react';
import { Toaster } from 'react-hot-toast';

interface CoreLayoutProps {
    children: React.ReactNode;
}

const CoreLayout = (props: CoreLayoutProps) => {
    return (
        <div className="relative min-h-full">
            <CoreHead />
            <NavigationBar />
            <main role={'main'} className="m-2">
                <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                    toastOptions={{ duration: 20000 }}
                />
                <Breadcrumbs />
                {props.children}
            </main>
            <Footer />
        </div>
    );
};

export default CoreLayout;
