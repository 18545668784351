import clsx from 'clsx';
import Link from 'next/link';
import React, { forwardRef } from 'react';

interface ProfileLinkProps {
    href: string;
    title: string;
    classNames?: string;
}

/**
 * See https://headlessui.dev/react/menu#integrating-with-next-js
 *
 * But the menu still doesn't close.  I need look into this.
 */
// eslint-disable-next-line react/display-name
const ProfileLink = forwardRef<HTMLAnchorElement, ProfileLinkProps>(
    ({ href, title, classNames }: ProfileLinkProps, ref) => {
        return (
            <Link href={href} className={clsx(classNames)} ref={ref}>
                {title}
            </Link>
        );
    }
);

export default ProfileLink;
