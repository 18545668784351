import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

interface StandardLinkProps {
    href: string;
    title: string;
    classNames?: string;
    underline?: boolean;
}

const StandardLink = ({ href, title, classNames, underline = true }: StandardLinkProps) => {
    return (
        <Link href={href} className={clsx(underline ? 'underline' : '', classNames)}>
            {title}
        </Link>
    );
};

export default StandardLink;
