import StandardLink from '@/components/app/link/StandardLink';
import Version from '@/components/layout/Version';
import React from 'react';

const Footer = () => {
    return (
        <div className="mt-12 text-sm text-gray-500">
            <div className="mx-auto grid max-w-3xl grid-cols-4">
                <div>
                    <StandardLink
                        title="MX"
                        href="/mx"
                        classNames="block font-bold"
                        underline={false}
                    />
                    <StandardLink href="/mx/registry" title="Registry" underline={false} />
                </div>
                <div>
                    <StandardLink
                        title="Cryo-EM"
                        href="/cryo-em"
                        classNames="block font-bold"
                        underline={false}
                    />
                </div>
                <div>
                    <StandardLink
                        title="SAXS"
                        href="/saxs"
                        classNames="block font-bold"
                        underline={false}
                    />
                </div>
                <div>
                    <div className="font-bold">Helix</div>
                    <div>Terms And Conditions</div>
                    <div>Privacy Policy</div>
                </div>
            </div>
            <div className="m-4 text-center">
                <Version />
            </div>
            <div className="pb-12 text-center">
                Copyright &copy; {new Date().getFullYear()}, Helix BioStructures, LLC
            </div>
        </div>
    );
};

export default Footer;
