import { ResponseString, VersionControllerApiFactory } from '@/api/api';
import { BASE_PATH } from '@/api/base';
import { Configuration } from '@/api/configuration';
import { axiosBuilder } from '@/queries/AxiosBuilder';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { LONG_CACHE } from '@/queries/QueryConstants';

export const useGetVersion = () => {
    const { getIdTokenClaims } = useAuth0();

    const fetchVersionData = async (): Promise<ResponseString> => {
        const axios = await axiosBuilder(getIdTokenClaims);

        return await VersionControllerApiFactory(new Configuration(), BASE_PATH, axios)
            .getVersion()
            .then((response) => response.data);
    };

    return useQuery<ResponseString, Error>(['version-getVersion'], fetchVersionData, {
        cacheTime: LONG_CACHE.CACHE,
        staleTime: LONG_CACHE.STALE,
    });
};
