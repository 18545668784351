import Module from '@/types/Module';
import { atom } from 'jotai';
import { Breadcrumb } from '@/types/Breadcrumbs';

interface PageMetadata {
    title?: string;
    description?: string;
    breadcrumbs?: Array<Breadcrumb>;
    module: Module;
}

export const pageMetadataAtom = atom<PageMetadata>({
    title: 'Dashboard',
    module: Module.NONE,
});
