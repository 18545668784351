var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Qgi-QLRRi1i_YgQBYoarz"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import environment from 'src/resources/environment.json';

const getDsn = () => {
    if ('local' === environment.environment.toLowerCase()) {
        return null;
    }

    return 'https://994b0c2d29634f398eb7e628b0209dae@o474894.ingest.sentry.io/6220483';
};

const getTracesSampleRate = () => {
    switch (environment.environment.toLowerCase()) {
        case 'local':
            return 0;

        case 'development':
            return 0.2;
    }

    return 0.1;
};

Sentry.init({
    dsn: getDsn(),
    environment: environment.environment,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: getTracesSampleRate(),
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
