import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

interface RequireAuthenticationProps {
    children: React.ReactNode;
}

const RequireAuthentication = (props: RequireAuthenticationProps) => {
    return <>{props.children}</>;
};

export default withAuthenticationRequired(RequireAuthentication);
