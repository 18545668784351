import environment from '@/resources/environment.json';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

const ValidatingCredentials = () => {
    const { logout } = useAuth0();

    return (
        <div className="relative h-screen w-full">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-xl font-bold text-gray-500 md:text-2xl">
                <div>Validating credentials ...</div>
                <div className="my-4">Please be patient.</div>
                <a
                    className="text cursor-pointer text-base underline"
                    onClick={() =>
                        logout({ logoutParams: { returnTo: environment.redirectURI }})
                    }
                    role="link"
                >
                    Return to Login
                </a>
            </div>
        </div>
    );
};

export default ValidatingCredentials;
