import Logo from '@/components/layout/Logo';
import ProfileDropdown from '@/components/layout/ProfileDropdown/ProfileDropdown';
import { pageMetadataAtom } from '@/components/PageMetadata';
import Module from '@/types/Module';
import { Disclosure } from '@headlessui/react';
import { useAtom } from 'jotai';
import Link from 'next/link';
import React from 'react';

// See https://tailwindui.com/components/application-ui/application-shells/stacked#component-e1d4a342c5903e8ade21c57c20448d69

function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(' ');
}

const NavigationBar = () => {
    const [pageMetadata] = useAtom(pageMetadataAtom);

    const navigation = [
        { name: 'MX', href: '/mx', module: Module.MX },
        { name: 'Cryo-EM', href: '/cryo-em', module: Module.CRYOEM },
        { name: 'SAXS', href: '/saxs', module: Module.SAXS },
        { name: 'Administration', href: '/administration', module: Module.ADMIN },
    ];

    return (
        <Disclosure as='nav' className='bg-blue-600'>
            {() => (
                <div className='mx-auto px-4'>
                    <div className='flex h-12 items-center justify-between'>
                        <div className='flex items-center'>
                            <div className='flex-shrink-0'>
                                <Logo />
                            </div>
                            <div className='ml-10 flex items-baseline space-x-4'>
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        href={item.href}
                                        className={classNames(
                                            item.module === pageMetadata.module
                                                ? 'bg-gray-100 text-blue-700'
                                                : 'text-white hover:bg-blue-500 hover:bg-opacity-75',
                                            'rounded-md px-3 py-2 text-sm font-bold',
                                        )}
                                        aria-current={
                                            item.module === pageMetadata.module
                                                ? 'page'
                                                : undefined
                                        }
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className='ml-4 flex items-center'>
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            )}
        </Disclosure>
    );
};

export default NavigationBar;
