import ProfileLink from '@/components/layout/ProfileDropdown/ProfileLink';
import SignOut from '@/components/layout/SignOut';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import md5 from 'md5';
import React, { Fragment } from 'react';

const profileNavigation = [
    { name: 'Profile', href: '/profile' },
    { name: 'Account', href: '/account' },
];

const ProfileDropdown = () => {
    const { user } = useAuth0();
    const gravatarUrl = () => {
        const hash = md5(
            user?.email !== undefined ? user.email.trim().toLowerCase() : ''
        ).toLowerCase();

        return 'https://www.gravatar.com/avatar/' + hash + '.jpg?s=32&d=mp';
    };

    return (
        <Menu as="div" className="relative ml-3">
            <Menu.Button className="flex max-w-xs items-center rounded-full bg-blue-600 text-sm focus:outline-none">
                <span className="sr-only">Open user menu</span>
                <img className="h-8 w-8 rounded-full" src={gravatarUrl()} alt="" />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-4 w-48 origin-top-right rounded-md border border-gray-200 bg-white py-1 shadow-lg focus:outline-none">
                    {profileNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                            {({ active }) => (
                                <ProfileLink
                                    href={item.href}
                                    title={item.name}
                                    classNames={clsx(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 hover:underline'
                                    )}
                                />
                            )}
                        </Menu.Item>
                    ))}
                    <Menu.Item>
                        <SignOut />
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default ProfileDropdown;
