import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import environment from '@/resources/environment.json';

const SignOut = () => {
    const { logout } = useAuth0();

    return (
        <a
            className="border-t- block cursor-pointer border-t border-t-gray-200 px-4 py-2 text-sm text-gray-700 hover:underline"
            onClick={() => logout({ logoutParams: { returnTo: environment.redirectURI }})}
            role="link"
        >
            Sign Out
        </a>
    );
};

export default SignOut;
