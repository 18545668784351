/* tslint:disable */
/* eslint-disable */
/**
 * HLX Client Portal
 * Helix BioStructures Client Portal API
 *
 * The version of the OpenAPI document: v2
 * Contact: jcarter@helixbiostructures.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BatchJobExecutionSummary
 */
export interface BatchJobExecutionSummary {
    /**
     * 
     * @type {number}
     * @memberof BatchJobExecutionSummary
     */
    'jobExecutionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchJobExecutionSummary
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobExecutionSummary
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobExecutionSummary
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobExecutionSummary
     */
    'exitCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobExecutionSummary
     */
    'exitMessage'?: string;
}
/**
 * The response content.
 * @export
 * @interface BeamlineSummary
 */
export interface BeamlineSummary {
    /**
     * The UUID of this beamline.
     * @type {string}
     * @memberof BeamlineSummary
     */
    'uuid': string;
    /**
     * The beamline code.
     * @type {string}
     * @memberof BeamlineSummary
     */
    'code': string;
    /**
     * The formal name of the beamline.
     * @type {string}
     * @memberof BeamlineSummary
     */
    'name': string;
    /**
     * 
     * @type {SynchrotronSummary}
     * @memberof BeamlineSummary
     */
    'synchrotron': SynchrotronSummary;
}
/**
 * 
 * @export
 * @interface BookingCreateForm
 */
export interface BookingCreateForm {
    /**
     * The UUID of this beamline.
     * @type {string}
     * @memberof BookingCreateForm
     */
    'beamlineUuid': string;
    /**
     * The start date at the synchrotron.
     * @type {string}
     * @memberof BookingCreateForm
     */
    'startDate': string;
    /**
     * The start time at the synchrotron.
     * @type {string}
     * @memberof BookingCreateForm
     */
    'startTime': string;
    /**
     * The duration (in hours) of the synchrotron collection.
     * @type {number}
     * @memberof BookingCreateForm
     */
    'durationHours': number;
    /**
     * The maximum number of samples allowed.
     * @type {number}
     * @memberof BookingCreateForm
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof BookingCreateForm
     */
    'visibility': BookingCreateFormVisibilityEnum;
    /**
     * The buffer for the maximum number of samples allowed.
     * @type {number}
     * @memberof BookingCreateForm
     */
    'buffer': number;
    /**
     * The date upon when all samples must be shipped by.
     * @type {string}
     * @memberof BookingCreateForm
     */
    'shipBy': string;
    /**
     * Synchrotron collection session notes that are displayed to the public.
     * @type {string}
     * @memberof BookingCreateForm
     */
    'publicNotes': string;
    /**
     * Synchrotron collection session notes that are for internal HLX use only.
     * @type {string}
     * @memberof BookingCreateForm
     */
    'privateNotes': string;
}

export const BookingCreateFormVisibilityEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;

export type BookingCreateFormVisibilityEnum = typeof BookingCreateFormVisibilityEnum[keyof typeof BookingCreateFormVisibilityEnum];

/**
 * The response content.
 * @export
 * @interface BookingDetail
 */
export interface BookingDetail {
    /**
     * The UUID of this booking.
     * @type {string}
     * @memberof BookingDetail
     */
    'uuid': string;
    /**
     * 
     * @type {BeamlineSummary}
     * @memberof BookingDetail
     */
    'beamline': BeamlineSummary;
    /**
     * The user-local starting date time in ISO-8601 format.
     * @type {string}
     * @memberof BookingDetail
     */
    'startTime': string;
    /**
     * The duration in hours.
     * @type {number}
     * @memberof BookingDetail
     */
    'durationHours': number;
    /**
     * The maximum number of samples allowed.
     * @type {number}
     * @memberof BookingDetail
     */
    'size': number;
    /**
     * The total number of available sample spaces.
     * @type {number}
     * @memberof BookingDetail
     */
    'availableSpaces': number;
    /**
     * The total number of reserved sample spaces.
     * @type {number}
     * @memberof BookingDetail
     */
    'reservedSpaces': number;
    /**
     * The buffer of samples allowed.
     * @type {number}
     * @memberof BookingDetail
     */
    'buffer': number;
    /**
     * The sample ship-by date.
     * @type {string}
     * @memberof BookingDetail
     */
    'shipBy': string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetail
     */
    'status': BookingDetailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingDetail
     */
    'visibility': BookingDetailVisibilityEnum;
    /**
     * Synchrotron collection session notes that are displayed to the public.
     * @type {string}
     * @memberof BookingDetail
     */
    'publicNotes': string;
    /**
     * Synchrotron collection session notes that are for internal HLX use ONLY.
     * @type {string}
     * @memberof BookingDetail
     */
    'privateNotes': string;
    /**
     * Only active Bookings are displayed to clients.
     * @type {boolean}
     * @memberof BookingDetail
     */
    'active': boolean;
    /**
     * The reservations attached to this booking.
     * @type {Array<BookingReservationSummary>}
     * @memberof BookingDetail
     */
    'reservations': Array<BookingReservationSummary>;
}

export const BookingDetailStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type BookingDetailStatusEnum = typeof BookingDetailStatusEnum[keyof typeof BookingDetailStatusEnum];
export const BookingDetailVisibilityEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;

export type BookingDetailVisibilityEnum = typeof BookingDetailVisibilityEnum[keyof typeof BookingDetailVisibilityEnum];

/**
 * 
 * @export
 * @interface BookingReservationAddSampleForm
 */
export interface BookingReservationAddSampleForm {
    /**
     * The number of samples to add.
     * @type {number}
     * @memberof BookingReservationAddSampleForm
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface BookingReservationForm
 */
export interface BookingReservationForm {
    /**
     * The UUID of this booking.
     * @type {string}
     * @memberof BookingReservationForm
     */
    'bookingUuid': string;
    /**
     * The number of samples the client is reserving.
     * @type {number}
     * @memberof BookingReservationForm
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface BookingReservationRemoveSampleForm
 */
export interface BookingReservationRemoveSampleForm {
    /**
     * A list of the UUIDs to remove.
     * @type {Array<string>}
     * @memberof BookingReservationRemoveSampleForm
     */
    'sampleUuids': Array<string>;
}
/**
 * The reservations attached to this booking.
 * @export
 * @interface BookingReservationSummary
 */
export interface BookingReservationSummary {
    /**
     * The UUID of this booking reservation.
     * @type {string}
     * @memberof BookingReservationSummary
     */
    'uuid': string;
    /**
     * 
     * @type {BookingSummary}
     * @memberof BookingReservationSummary
     */
    'booking': BookingSummary;
    /**
     * 
     * @type {ClientSummary}
     * @memberof BookingReservationSummary
     */
    'client': ClientSummary;
    /**
     * The number of samples the client reserved.
     * @type {number}
     * @memberof BookingReservationSummary
     */
    'size': number;
    /**
     * The maximum number of samples the client can reserve.
     * @type {number}
     * @memberof BookingReservationSummary
     */
    'maxSize': number;
}
/**
 * 
 * @export
 * @interface BookingReservationUpdateForm
 */
export interface BookingReservationUpdateForm {
    /**
     * The updated number of samples the client is reserving. If the size is zero, the booking reservation will be deleted.
     * @type {number}
     * @memberof BookingReservationUpdateForm
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface BookingSummary
 */
export interface BookingSummary {
    /**
     * The UUID of this booking.
     * @type {string}
     * @memberof BookingSummary
     */
    'uuid': string;
    /**
     * 
     * @type {BeamlineSummary}
     * @memberof BookingSummary
     */
    'beamline': BeamlineSummary;
    /**
     * The user-local starting date time in ISO-8601 format.
     * @type {string}
     * @memberof BookingSummary
     */
    'startTime': string;
    /**
     * The expected collection duration in hours.
     * @type {number}
     * @memberof BookingSummary
     */
    'durationHours': number;
    /**
     * The maximum number of samples allowed.
     * @type {number}
     * @memberof BookingSummary
     */
    'size': number;
    /**
     * The total number of available sample spaces.
     * @type {number}
     * @memberof BookingSummary
     */
    'availableSpaces': number;
    /**
     * The total number of reserved sample spaces.
     * @type {number}
     * @memberof BookingSummary
     */
    'reservedSpaces': number;
    /**
     * The buffer of samples allowed.
     * @type {number}
     * @memberof BookingSummary
     */
    'buffer': number;
    /**
     * The sample ship-by date.
     * @type {string}
     * @memberof BookingSummary
     */
    'shipBy': string;
    /**
     * 
     * @type {string}
     * @memberof BookingSummary
     */
    'status': BookingSummaryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingSummary
     */
    'visibility': BookingSummaryVisibilityEnum;
    /**
     * Synchrotron collection session notes that are displayed to the public.
     * @type {string}
     * @memberof BookingSummary
     */
    'publicNotes': string;
    /**
     * Synchrotron collection session notes that are ONLY displayed to HLX employees.
     * @type {string}
     * @memberof BookingSummary
     */
    'privateNotes': string;
    /**
     * Only active Bookings are displayed to clients.
     * @type {boolean}
     * @memberof BookingSummary
     */
    'active': boolean;
}

export const BookingSummaryStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type BookingSummaryStatusEnum = typeof BookingSummaryStatusEnum[keyof typeof BookingSummaryStatusEnum];
export const BookingSummaryVisibilityEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;

export type BookingSummaryVisibilityEnum = typeof BookingSummaryVisibilityEnum[keyof typeof BookingSummaryVisibilityEnum];

/**
 * 
 * @export
 * @interface BookingUpdateForm
 */
export interface BookingUpdateForm {
    /**
     * The start date at the synchrotron.
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'startDate': string;
    /**
     * The start time at the synchrotron.
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'startTime': string;
    /**
     * The duration (in hours) of the synchrotron collection.
     * @type {number}
     * @memberof BookingUpdateForm
     */
    'durationHours': number;
    /**
     * The maximum number of samples allowed.
     * @type {number}
     * @memberof BookingUpdateForm
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'status': BookingUpdateFormStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'visibility': BookingUpdateFormVisibilityEnum;
    /**
     * The buffer for the maximum number of samples allowed.
     * @type {number}
     * @memberof BookingUpdateForm
     */
    'buffer': number;
    /**
     * The date upon when all samples must be shipped by.
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'shipBy': string;
    /**
     * Synchrotron collection session notes that are displayed to the public.
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'publicNotes': string;
    /**
     * Synchrotron collection session notes that are for internal HLX use only.
     * @type {string}
     * @memberof BookingUpdateForm
     */
    'privateNotes': string;
}

export const BookingUpdateFormStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type BookingUpdateFormStatusEnum = typeof BookingUpdateFormStatusEnum[keyof typeof BookingUpdateFormStatusEnum];
export const BookingUpdateFormVisibilityEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;

export type BookingUpdateFormVisibilityEnum = typeof BookingUpdateFormVisibilityEnum[keyof typeof BookingUpdateFormVisibilityEnum];

/**
 * 
 * @export
 * @interface ClientSummary
 */
export interface ClientSummary {
    /**
     * The UUID of this client.
     * @type {string}
     * @memberof ClientSummary
     */
    'uuid': string;
    /**
     * 
     * @type {CompanySummary}
     * @memberof ClientSummary
     */
    'company': CompanySummary;
    /**
     * The name of the client organization. Typically, a department or location. If the company is small, then often the company name is repeated here.
     * @type {string}
     * @memberof ClientSummary
     */
    'name': string;
    /**
     * The client code.
     * @type {string}
     * @memberof ClientSummary
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CompanySummary
 */
export interface CompanySummary {
    /**
     * The UUID of this company.
     * @type {string}
     * @memberof CompanySummary
     */
    'uuid': string;
    /**
     * The formal name of the company.
     * @type {string}
     * @memberof CompanySummary
     */
    'name': string;
    /**
     * The company code.
     * @type {string}
     * @memberof CompanySummary
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CompoundForm
 */
export interface CompoundForm {
    /**
     * The compound name.
     * @type {string}
     * @memberof CompoundForm
     */
    'name': string;
    /**
     * The SMILES string that represents this compound.
     * @type {string}
     * @memberof CompoundForm
     */
    'smiles'?: string;
}
/**
 * The response content.
 * @export
 * @interface CompoundSummary
 */
export interface CompoundSummary {
    /**
     * The UUID of this compound.
     * @type {string}
     * @memberof CompoundSummary
     */
    'uuid': string;
    /**
     * The compound name.
     * @type {string}
     * @memberof CompoundSummary
     */
    'name': string;
    /**
     * 
     * @type {ClientSummary}
     * @memberof CompoundSummary
     */
    'client': ClientSummary;
    /**
     * The SMILES string that represents this compound.
     * @type {string}
     * @memberof CompoundSummary
     */
    'smiles'?: string;
}
/**
 * The response content.
 * @export
 * @interface CreationResponse
 */
export interface CreationResponse {
    /**
     * True if the entity / process was successful.
     * @type {boolean}
     * @memberof CreationResponse
     */
    'success': boolean;
    /**
     * Typically, an error message.
     * @type {string}
     * @memberof CreationResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DatasetAbstract
 */
export interface DatasetAbstract {
    /**
     * The UUID of this file.
     * @type {string}
     * @memberof DatasetAbstract
     */
    'uuid': string;
    /**
     * The sample name.
     * @type {string}
     * @memberof DatasetAbstract
     */
    'sampleName': string;
    /**
     * The dataset identifier.
     * @type {string}
     * @memberof DatasetAbstract
     */
    'identifier': string;
    /**
     * The dataset type.
     * @type {string}
     * @memberof DatasetAbstract
     */
    'type': DatasetAbstractTypeEnum;
}

export const DatasetAbstractTypeEnum = {
    Collection: 'COLLECTION',
    Screening: 'SCREENING',
    None: 'NONE',
    Unknown: 'UNKNOWN'
} as const;

export type DatasetAbstractTypeEnum = typeof DatasetAbstractTypeEnum[keyof typeof DatasetAbstractTypeEnum];

/**
 * 
 * @export
 * @interface DatasetSummary
 */
export interface DatasetSummary {
    /**
     * The UUID of this file.
     * @type {string}
     * @memberof DatasetSummary
     */
    'uuid': string;
    /**
     * 
     * @type {SampleAbstract}
     * @memberof DatasetSummary
     */
    'sample': SampleAbstract;
    /**
     * The dataset identifier.
     * @type {string}
     * @memberof DatasetSummary
     */
    'identifier': string;
    /**
     * The dataset type.
     * @type {string}
     * @memberof DatasetSummary
     */
    'type': DatasetSummaryTypeEnum;
    /**
     * 
     * @type {Array<JobAbstract>}
     * @memberof DatasetSummary
     */
    'jobs': Array<JobAbstract>;
}

export const DatasetSummaryTypeEnum = {
    Collection: 'COLLECTION',
    Screening: 'SCREENING',
    None: 'NONE',
    Unknown: 'UNKNOWN'
} as const;

export type DatasetSummaryTypeEnum = typeof DatasetSummaryTypeEnum[keyof typeof DatasetSummaryTypeEnum];

/**
 * 
 * @export
 * @interface JobAbstract
 */
export interface JobAbstract {
    /**
     * The UUID of this file.
     * @type {string}
     * @memberof JobAbstract
     */
    'uuid': string;
    /**
     * The name of this job.
     * @type {string}
     * @memberof JobAbstract
     */
    'name': string;
    /**
     * The status of this job.
     * @type {string}
     * @memberof JobAbstract
     */
    'status': JobAbstractStatusEnum;
    /**
     * The date this job was promoted.
     * @type {string}
     * @memberof JobAbstract
     */
    'promotedOn'?: string;
    /**
     * The job trace id.
     * @type {string}
     * @memberof JobAbstract
     */
    'traceId': string;
}

export const JobAbstractStatusEnum = {
    Submitted: 'SUBMITTED',
    Queued: 'QUEUED',
    Started: 'STARTED',
    Success: 'SUCCESS',
    Abandoned: 'ABANDONED',
    Failure: 'FAILURE',
    Timeout: 'TIMEOUT'
} as const;

export type JobAbstractStatusEnum = typeof JobAbstractStatusEnum[keyof typeof JobAbstractStatusEnum];

/**
 * The response content.
 * @export
 * @interface JobDetail
 */
export interface JobDetail {
    /**
     * The UUID of this file.
     * @type {string}
     * @memberof JobDetail
     */
    'uuid': string;
    /**
     * The name of this job.
     * @type {string}
     * @memberof JobDetail
     */
    'jobName': string;
    /**
     * The name of the program running this job.
     * @type {string}
     * @memberof JobDetail
     */
    'programName': string;
    /**
     * The program version running this job.
     * @type {string}
     * @memberof JobDetail
     */
    'programVersion': string;
    /**
     * The status of this job.
     * @type {string}
     * @memberof JobDetail
     */
    'status': JobDetailStatusEnum;
    /**
     * The date this job was promoted.
     * @type {string}
     * @memberof JobDetail
     */
    'promotedOn'?: string;
    /**
     * 
     * @type {DatasetAbstract}
     * @memberof JobDetail
     */
    'dataset': DatasetAbstract;
    /**
     * 
     * @type {Array<JobIOSummary>}
     * @memberof JobDetail
     */
    'parameters': Array<JobIOSummary>;
    /**
     * 
     * @type {Array<JobIOSummary>}
     * @memberof JobDetail
     */
    'results': Array<JobIOSummary>;
    /**
     * The job trace id.
     * @type {string}
     * @memberof JobDetail
     */
    'traceId': string;
    /**
     * 
     * @type {BatchJobExecutionSummary}
     * @memberof JobDetail
     */
    'batchJobExecutionSummary'?: BatchJobExecutionSummary;
}

export const JobDetailStatusEnum = {
    Submitted: 'SUBMITTED',
    Queued: 'QUEUED',
    Started: 'STARTED',
    Success: 'SUCCESS',
    Abandoned: 'ABANDONED',
    Failure: 'FAILURE',
    Timeout: 'TIMEOUT'
} as const;

export type JobDetailStatusEnum = typeof JobDetailStatusEnum[keyof typeof JobDetailStatusEnum];

/**
 * 
 * @export
 * @interface JobIOSummary
 */
export interface JobIOSummary {
    /**
     * 
     * @type {string}
     * @memberof JobIOSummary
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof JobIOSummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof JobIOSummary
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof JobIOSummary
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ListResponseBookingReservationSummary
 */
export interface ListResponseBookingReservationSummary {
    /**
     * The response content.
     * @type {Array<BookingReservationSummary>}
     * @memberof ListResponseBookingReservationSummary
     */
    'content': Array<BookingReservationSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseBookingReservationSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponseClientSummary
 */
export interface ListResponseClientSummary {
    /**
     * The response content.
     * @type {Array<ClientSummary>}
     * @memberof ListResponseClientSummary
     */
    'content': Array<ClientSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseClientSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponseDatasetSummary
 */
export interface ListResponseDatasetSummary {
    /**
     * The response content.
     * @type {Array<DatasetSummary>}
     * @memberof ListResponseDatasetSummary
     */
    'content': Array<DatasetSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseDatasetSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponseJobDetail
 */
export interface ListResponseJobDetail {
    /**
     * The response content.
     * @type {Array<JobDetail>}
     * @memberof ListResponseJobDetail
     */
    'content': Array<JobDetail>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseJobDetail
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponsePuckTypeDetail
 */
export interface ListResponsePuckTypeDetail {
    /**
     * The response content.
     * @type {Array<PuckTypeDetail>}
     * @memberof ListResponsePuckTypeDetail
     */
    'content': Array<PuckTypeDetail>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponsePuckTypeDetail
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponseSampleDetail
 */
export interface ListResponseSampleDetail {
    /**
     * The response content.
     * @type {Array<SampleDetail>}
     * @memberof ListResponseSampleDetail
     */
    'content': Array<SampleDetail>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseSampleDetail
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponseSampleEntryDetail
 */
export interface ListResponseSampleEntryDetail {
    /**
     * The response content.
     * @type {Array<SampleEntryDetail>}
     * @memberof ListResponseSampleEntryDetail
     */
    'content': Array<SampleEntryDetail>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseSampleEntryDetail
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ListResponseWorkflowSummary
 */
export interface ListResponseWorkflowSummary {
    /**
     * The response content.
     * @type {Array<WorkflowSummary>}
     * @memberof ListResponseWorkflowSummary
     */
    'content': Array<WorkflowSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ListResponseWorkflowSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * The response content.
 * @export
 * @interface MxDataCollectionSummary
 */
export interface MxDataCollectionSummary {
    /**
     * 
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'uuid': string;
    /**
     * The collection date.
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'date': string;
    /**
     * 
     * @type {BeamlineSummary}
     * @memberof MxDataCollectionSummary
     */
    'beamline': BeamlineSummary;
    /**
     * 
     * @type {ClientSummary}
     * @memberof MxDataCollectionSummary
     */
    'client': ClientSummary;
    /**
     * The number of samples in the collection.
     * @type {number}
     * @memberof MxDataCollectionSummary
     */
    'sampleCount': number;
    /**
     * The number of jobs executed with this sample.
     * @type {number}
     * @memberof MxDataCollectionSummary
     */
    'jobCount': number;
    /**
     * The reservation ship by date.
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'reservationShipBy'?: string;
    /**
     * The reservation sample count.
     * @type {number}
     * @memberof MxDataCollectionSummary
     */
    'reservationSampleCount'?: number;
    /**
     * The status of the MX Data Collection.
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'status': MxDataCollectionSummaryStatusEnum;
    /**
     * The shipping status of the MX Data Collection.
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'shippingStatus': MxDataCollectionSummaryShippingStatusEnum;
    /**
     * The synchrotron collection status of the MX Data Collection.
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'collectionStatus': MxDataCollectionSummaryCollectionStatusEnum;
    /**
     * The transfer status of the MX Data Collection.
     * @type {string}
     * @memberof MxDataCollectionSummary
     */
    'transferStatus': MxDataCollectionSummaryTransferStatusEnum;
}

export const MxDataCollectionSummaryStatusEnum = {
    Active: 'ACTIVE',
    CancelledByClient: 'CANCELLED_BY_CLIENT',
    CancelledByHlx: 'CANCELLED_BY_HLX'
} as const;

export type MxDataCollectionSummaryStatusEnum = typeof MxDataCollectionSummaryStatusEnum[keyof typeof MxDataCollectionSummaryStatusEnum];
export const MxDataCollectionSummaryShippingStatusEnum = {
    Preparing: 'PREPARING',
    Ready: 'READY',
    Completed: 'COMPLETED'
} as const;

export type MxDataCollectionSummaryShippingStatusEnum = typeof MxDataCollectionSummaryShippingStatusEnum[keyof typeof MxDataCollectionSummaryShippingStatusEnum];
export const MxDataCollectionSummaryCollectionStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Started: 'STARTED',
    Completed: 'COMPLETED',
    Error: 'ERROR'
} as const;

export type MxDataCollectionSummaryCollectionStatusEnum = typeof MxDataCollectionSummaryCollectionStatusEnum[keyof typeof MxDataCollectionSummaryCollectionStatusEnum];
export const MxDataCollectionSummaryTransferStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Started: 'STARTED',
    Completed: 'COMPLETED',
    Error: 'ERROR'
} as const;

export type MxDataCollectionSummaryTransferStatusEnum = typeof MxDataCollectionSummaryTransferStatusEnum[keyof typeof MxDataCollectionSummaryTransferStatusEnum];

/**
 * The pagination information.
 * @export
 * @interface PageableResponse
 */
export interface PageableResponse {
    /**
     * The maximum number of items returned per page.
     * @type {number}
     * @memberof PageableResponse
     */
    'pageSize': number;
    /**
     * The total number of pages
     * @type {number}
     * @memberof PageableResponse
     */
    'totalPages': number;
    /**
     * Zero-based page index
     * @type {number}
     * @memberof PageableResponse
     */
    'page': number;
    /**
     * The total number of items
     * @type {number}
     * @memberof PageableResponse
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface PaginatedResponseBeamlineSummary
 */
export interface PaginatedResponseBeamlineSummary {
    /**
     * The response content.
     * @type {Array<BeamlineSummary>}
     * @memberof PaginatedResponseBeamlineSummary
     */
    'content': Array<BeamlineSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseBeamlineSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseBeamlineSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseBookingDetail
 */
export interface PaginatedResponseBookingDetail {
    /**
     * The response content.
     * @type {Array<BookingDetail>}
     * @memberof PaginatedResponseBookingDetail
     */
    'content': Array<BookingDetail>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseBookingDetail
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseBookingDetail
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseBookingSummary
 */
export interface PaginatedResponseBookingSummary {
    /**
     * The response content.
     * @type {Array<BookingSummary>}
     * @memberof PaginatedResponseBookingSummary
     */
    'content': Array<BookingSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseBookingSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseBookingSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseCompoundSummary
 */
export interface PaginatedResponseCompoundSummary {
    /**
     * The response content.
     * @type {Array<CompoundSummary>}
     * @memberof PaginatedResponseCompoundSummary
     */
    'content': Array<CompoundSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseCompoundSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseCompoundSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseMxDataCollectionSummary
 */
export interface PaginatedResponseMxDataCollectionSummary {
    /**
     * The response content.
     * @type {Array<MxDataCollectionSummary>}
     * @memberof PaginatedResponseMxDataCollectionSummary
     */
    'content': Array<MxDataCollectionSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseMxDataCollectionSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseMxDataCollectionSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseProteinSummary
 */
export interface PaginatedResponseProteinSummary {
    /**
     * The response content.
     * @type {Array<ProteinSummary>}
     * @memberof PaginatedResponseProteinSummary
     */
    'content': Array<ProteinSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseProteinSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseProteinSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseShippingDocumentSummary
 */
export interface PaginatedResponseShippingDocumentSummary {
    /**
     * The response content.
     * @type {Array<ShippingDocumentSummary>}
     * @memberof PaginatedResponseShippingDocumentSummary
     */
    'content': Array<ShippingDocumentSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseShippingDocumentSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseShippingDocumentSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface PaginatedResponseSynchrotronSummary
 */
export interface PaginatedResponseSynchrotronSummary {
    /**
     * The response content.
     * @type {Array<SynchrotronSummary>}
     * @memberof PaginatedResponseSynchrotronSummary
     */
    'content': Array<SynchrotronSummary>;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof PaginatedResponseSynchrotronSummary
     */
    'metadata': ResponseMetadata;
    /**
     * 
     * @type {PageableResponse}
     * @memberof PaginatedResponseSynchrotronSummary
     */
    'pagination': PageableResponse;
}
/**
 * 
 * @export
 * @interface ProteinForm
 */
export interface ProteinForm {
    /**
     * The protein name.
     * @type {string}
     * @memberof ProteinForm
     */
    'name': string;
    /**
     * The PDB ID.
     * @type {string}
     * @memberof ProteinForm
     */
    'pdbId'?: string;
    /**
     * The FASTA file comment.
     * @type {string}
     * @memberof ProteinForm
     */
    'fastaComment'?: string;
    /**
     * The FASTA file sequence.
     * @type {string}
     * @memberof ProteinForm
     */
    'fastaSequence'?: string;
}
/**
 * The response content.
 * @export
 * @interface ProteinSummary
 */
export interface ProteinSummary {
    /**
     * The UUID of this protein.
     * @type {string}
     * @memberof ProteinSummary
     */
    'uuid': string;
    /**
     * The protein name.
     * @type {string}
     * @memberof ProteinSummary
     */
    'name': string;
    /**
     * 
     * @type {ClientSummary}
     * @memberof ProteinSummary
     */
    'client': ClientSummary;
    /**
     * The PDB ID.
     * @type {string}
     * @memberof ProteinSummary
     */
    'pdbId'?: string;
    /**
     * The FASTA file comment.
     * @type {string}
     * @memberof ProteinSummary
     */
    'fastaComment'?: string;
    /**
     * The FASTA file sequence.
     * @type {string}
     * @memberof ProteinSummary
     */
    'fastaSequence'?: string;
}
/**
 * The response content.
 * @export
 * @interface PuckTypeDetail
 */
export interface PuckTypeDetail {
    /**
     * The puck type.
     * @type {string}
     * @memberof PuckTypeDetail
     */
    'puckType': string;
    /**
     * The puck type description.
     * @type {string}
     * @memberof PuckTypeDetail
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ResponseBookingDetail
 */
export interface ResponseBookingDetail {
    /**
     * 
     * @type {BookingDetail}
     * @memberof ResponseBookingDetail
     */
    'content': BookingDetail;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseBookingDetail
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseBookingReservationSummary
 */
export interface ResponseBookingReservationSummary {
    /**
     * 
     * @type {BookingReservationSummary}
     * @memberof ResponseBookingReservationSummary
     */
    'content': BookingReservationSummary;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseBookingReservationSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseCreationResponse
 */
export interface ResponseCreationResponse {
    /**
     * 
     * @type {CreationResponse}
     * @memberof ResponseCreationResponse
     */
    'content': CreationResponse;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseCreationResponse
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseDatasetSummary
 */
export interface ResponseDatasetSummary {
    /**
     * 
     * @type {DatasetSummary}
     * @memberof ResponseDatasetSummary
     */
    'content': DatasetSummary;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseDatasetSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseJobDetail
 */
export interface ResponseJobDetail {
    /**
     * 
     * @type {JobDetail}
     * @memberof ResponseJobDetail
     */
    'content': JobDetail;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseJobDetail
     */
    'metadata': ResponseMetadata;
}
/**
 * The response metadata.
 * @export
 * @interface ResponseMetadata
 */
export interface ResponseMetadata {
    /**
     * The URL path of the request.
     * @type {string}
     * @memberof ResponseMetadata
     */
    'request-path': string;
    /**
     * The date and time of the API response.
     * @type {string}
     * @memberof ResponseMetadata
     */
    'timestamp': string;
    /**
     * The HTTP status code of the API response.
     * @type {number}
     * @memberof ResponseMetadata
     */
    'http-status-code': number;
}
/**
 * 
 * @export
 * @interface ResponseMxDataCollectionSummary
 */
export interface ResponseMxDataCollectionSummary {
    /**
     * 
     * @type {MxDataCollectionSummary}
     * @memberof ResponseMxDataCollectionSummary
     */
    'content': MxDataCollectionSummary;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseMxDataCollectionSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseString
 */
export interface ResponseString {
    /**
     * The response content.
     * @type {string}
     * @memberof ResponseString
     */
    'content': string;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseString
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseSynchrotronSummary
 */
export interface ResponseSynchrotronSummary {
    /**
     * 
     * @type {SynchrotronSummary}
     * @memberof ResponseSynchrotronSummary
     */
    'content': SynchrotronSummary;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseSynchrotronSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface ResponseUserSummary
 */
export interface ResponseUserSummary {
    /**
     * 
     * @type {UserSummary}
     * @memberof ResponseUserSummary
     */
    'content': UserSummary;
    /**
     * 
     * @type {ResponseMetadata}
     * @memberof ResponseUserSummary
     */
    'metadata': ResponseMetadata;
}
/**
 * 
 * @export
 * @interface SampleAbstract
 */
export interface SampleAbstract {
    /**
     * The UUID of this sample.
     * @type {string}
     * @memberof SampleAbstract
     */
    'uuid': string;
    /**
     * The sample name. Must conform to the following regex: [a-zA-Z0-9-_]
     * @type {string}
     * @memberof SampleAbstract
     */
    'name': string;
    /**
     * The collection status.
     * @type {string}
     * @memberof SampleAbstract
     */
    'status': SampleAbstractStatusEnum;
}

export const SampleAbstractStatusEnum = {
    DataEntry: 'Data Entry',
    Shipping: 'Shipping',
    Collected: 'Collected',
    Screened: 'Screened',
    Skipped: 'Skipped',
    Cancelled: 'Cancelled'
} as const;

export type SampleAbstractStatusEnum = typeof SampleAbstractStatusEnum[keyof typeof SampleAbstractStatusEnum];

/**
 * The response content.
 * @export
 * @interface SampleDetail
 */
export interface SampleDetail {
    /**
     * The UUID of this sample.
     * @type {string}
     * @memberof SampleDetail
     */
    'uuid': string;
    /**
     * The sample name. Must conform to the following regex: [a-zA-Z0-9-_]
     * @type {string}
     * @memberof SampleDetail
     */
    'name': string;
    /**
     * 
     * @type {Array<DatasetSummary>}
     * @memberof SampleDetail
     */
    'datasets': Array<DatasetSummary>;
    /**
     * The collection status.
     * @type {string}
     * @memberof SampleDetail
     */
    'status': SampleDetailStatusEnum;
    /**
     * The date the collection took place. Will be null if the collection has never taken place.
     * @type {string}
     * @memberof SampleDetail
     */
    'collectionDate'?: string;
    /**
     * 
     * @type {WorkflowAbstract}
     * @memberof SampleDetail
     */
    'workflow': WorkflowAbstract;
}

export const SampleDetailStatusEnum = {
    DataEntry: 'Data Entry',
    Shipping: 'Shipping',
    Collected: 'Collected',
    Screened: 'Screened',
    Skipped: 'Skipped',
    Cancelled: 'Cancelled'
} as const;

export type SampleDetailStatusEnum = typeof SampleDetailStatusEnum[keyof typeof SampleDetailStatusEnum];

/**
 * 
 * @export
 * @interface SampleEntryDetail
 */
export interface SampleEntryDetail {
    /**
     * The UUID of this sample.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'uuid': string;
    /**
     * The sample name. Must conform to the following regex: [a-zA-Z0-9-_]
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'name': string;
    /**
     * The puck type.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'puckType'?: string;
    /**
     * The puck identifier.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'puckIdentifier'?: string;
    /**
     * The puck position.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'puckPosition'?: string;
    /**
     * The minimum collectible resolution.
     * @type {number}
     * @memberof SampleEntryDetail
     */
    'collectibleResolution'?: number;
    /**
     * Collection instructions given by the client for this particular sample.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'collectionInstructions'?: string;
    /**
     * A comma-separated list of compound names.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'compounds': string;
    /**
     * The sample grouping.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'grouping'?: string;
    /**
     * The expected heavy atom for this sample.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'expectedHeavyAtom'?: string;
    /**
     * The expected space group for this sample.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'expectedSpaceGroup'?: string;
    /**
     * The expected unit cell for this sample.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'expectedUnitCell'?: string;
    /**
     * A comma-separated list of protein names.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'proteins': string;
    /**
     * The sample priority.
     * @type {number}
     * @memberof SampleEntryDetail
     */
    'samplePriority'?: number;
    /**
     * The resolution at which the HLX-OPERATOR should stop screening.
     * @type {number}
     * @memberof SampleEntryDetail
     */
    'stopScreeningResolution'?: number;
    /**
     * This sample\'s workflow.
     * @type {string}
     * @memberof SampleEntryDetail
     */
    'workflow': string;
}
/**
 * The response content.
 * @export
 * @interface ShippingDocumentSummary
 */
export interface ShippingDocumentSummary {
    /**
     * The UUID of this shipping document.
     * @type {string}
     * @memberof ShippingDocumentSummary
     */
    'uuid': string;
    /**
     * The UUID of the booking reservation for which this shipping document is for.
     * @type {string}
     * @memberof ShippingDocumentSummary
     */
    'bookingReservationUuid': string;
    /**
     * The name of the shipping document.
     * @type {string}
     * @memberof ShippingDocumentSummary
     */
    'name': string;
    /**
     * The shipping document notes.
     * @type {string}
     * @memberof ShippingDocumentSummary
     */
    'notes'?: string;
}
/**
 * The response content.
 * @export
 * @interface SynchrotronSummary
 */
export interface SynchrotronSummary {
    /**
     * The UUID of this synchrotron.
     * @type {string}
     * @memberof SynchrotronSummary
     */
    'uuid': string;
    /**
     * The synchrotron code.
     * @type {string}
     * @memberof SynchrotronSummary
     */
    'code': string;
    /**
     * The formal name of the synchrotron.
     * @type {string}
     * @memberof SynchrotronSummary
     */
    'name': string;
    /**
     * The synchrotron timezone.
     * @type {string}
     * @memberof SynchrotronSummary
     */
    'timezone': string;
    /**
     * The synchrotron\'s public URL.
     * @type {string}
     * @memberof SynchrotronSummary
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
    /**
     * 
     * @type {File}
     * @memberof UploadFileRequest
     */
    'file': File;
}
/**
 * The response content.
 * @export
 * @interface UserSummary
 */
export interface UserSummary {
    /**
     * The UUID of this user.
     * @type {string}
     * @memberof UserSummary
     */
    'uuid': string;
    /**
     * 
     * @type {ClientSummary}
     * @memberof UserSummary
     */
    'client': ClientSummary;
    /**
     * The user\'s email address.
     * @type {string}
     * @memberof UserSummary
     */
    'email': string;
    /**
     * The user\'s timezone.
     * @type {string}
     * @memberof UserSummary
     */
    'timezone': string;
    /**
     * A list of roles assigned to the user.
     * @type {Array<string>}
     * @memberof UserSummary
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface WorkflowAbstract
 */
export interface WorkflowAbstract {
    /**
     * 
     * @type {string}
     * @memberof WorkflowAbstract
     */
    'uuid': string;
    /**
     * The display name of the workflow.
     * @type {string}
     * @memberof WorkflowAbstract
     */
    'name': string;
}
/**
 * The response content.
 * @export
 * @interface WorkflowSummary
 */
export interface WorkflowSummary {
    /**
     * 
     * @type {string}
     * @memberof WorkflowSummary
     */
    'uuid': string;
    /**
     * 
     * @type {ClientSummary}
     * @memberof WorkflowSummary
     */
    'client': ClientSummary;
    /**
     * The display name of the workflow.
     * @type {string}
     * @memberof WorkflowSummary
     */
    'name': string;
}

/**
 * BeamlineControllerApi - axios parameter creator
 * @export
 */
export const BeamlineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of all beamlines.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBeamlines: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/beamline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeamlineControllerApi - functional programming interface
 * @export
 */
export const BeamlineControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeamlineControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a paginated list of all beamlines.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBeamlines(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseBeamlineSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBeamlines(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeamlineControllerApi - factory interface
 * @export
 */
export const BeamlineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeamlineControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a paginated list of all beamlines.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBeamlines(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseBeamlineSummary> {
            return localVarFp.getAllBeamlines(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BeamlineControllerApi - object-oriented interface
 * @export
 * @class BeamlineControllerApi
 * @extends {BaseAPI}
 */
export class BeamlineControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of all beamlines.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeamlineControllerApi
     */
    public getAllBeamlines(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return BeamlineControllerApiFp(this.configuration).getAllBeamlines(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingControllerApi - axios parameter creator
 * @export
 */
export const BookingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new booking
         * @param {BookingCreateForm} bookingCreateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking: async (bookingCreateForm: BookingCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCreateForm' is not null or undefined
            assertParamExists('createBooking', 'bookingCreateForm', bookingCreateForm)
            const localVarPath = `/api/v2/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a booking
         * @param {string} bookingUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBooking: async (bookingUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingUuid' is not null or undefined
            assertParamExists('deleteBooking', 'bookingUuid', bookingUuid)
            const localVarPath = `/api/v2/booking/{bookingUuid}`
                .replace(`{${"bookingUuid"}}`, encodeURIComponent(String(bookingUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of all bookings
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBookings: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of open bookings for the current user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenBookings: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/booking/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single booking by UUID
         * @param {string} bookingUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleBookingByUuid: async (bookingUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingUuid' is not null or undefined
            assertParamExists('getSingleBookingByUuid', 'bookingUuid', bookingUuid)
            const localVarPath = `/api/v2/booking/{bookingUuid}`
                .replace(`{${"bookingUuid"}}`, encodeURIComponent(String(bookingUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a booking
         * @param {string} bookingUuid 
         * @param {BookingUpdateForm} bookingUpdateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking: async (bookingUuid: string, bookingUpdateForm: BookingUpdateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingUuid' is not null or undefined
            assertParamExists('updateBooking', 'bookingUuid', bookingUuid)
            // verify required parameter 'bookingUpdateForm' is not null or undefined
            assertParamExists('updateBooking', 'bookingUpdateForm', bookingUpdateForm)
            const localVarPath = `/api/v2/booking/{bookingUuid}`
                .replace(`{${"bookingUuid"}}`, encodeURIComponent(String(bookingUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingUpdateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a booking reservation by booking UUID
         * @param {string} bookingUuid 
         * @param {BookingReservationUpdateForm} bookingReservationUpdateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleBookingReservationByUuid: async (bookingUuid: string, bookingReservationUpdateForm: BookingReservationUpdateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingUuid' is not null or undefined
            assertParamExists('updateSingleBookingReservationByUuid', 'bookingUuid', bookingUuid)
            // verify required parameter 'bookingReservationUpdateForm' is not null or undefined
            assertParamExists('updateSingleBookingReservationByUuid', 'bookingReservationUpdateForm', bookingReservationUpdateForm)
            const localVarPath = `/api/v2/booking/{bookingUuid}/reservation`
                .replace(`{${"bookingUuid"}}`, encodeURIComponent(String(bookingUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingReservationUpdateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingControllerApi - functional programming interface
 * @export
 */
export const BookingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new booking
         * @param {BookingCreateForm} bookingCreateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBooking(bookingCreateForm: BookingCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBooking(bookingCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a booking
         * @param {string} bookingUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBooking(bookingUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBooking(bookingUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of all bookings
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBookings(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseBookingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBookings(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of open bookings for the current user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenBookings(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseBookingSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenBookings(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single booking by UUID
         * @param {string} bookingUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleBookingByUuid(bookingUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBookingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleBookingByUuid(bookingUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a booking
         * @param {string} bookingUuid 
         * @param {BookingUpdateForm} bookingUpdateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBooking(bookingUuid: string, bookingUpdateForm: BookingUpdateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBooking(bookingUuid, bookingUpdateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a booking reservation by booking UUID
         * @param {string} bookingUuid 
         * @param {BookingReservationUpdateForm} bookingReservationUpdateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleBookingReservationByUuid(bookingUuid: string, bookingReservationUpdateForm: BookingReservationUpdateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleBookingReservationByUuid(bookingUuid, bookingReservationUpdateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingControllerApi - factory interface
 * @export
 */
export const BookingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new booking
         * @param {BookingCreateForm} bookingCreateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(bookingCreateForm: BookingCreateForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.createBooking(bookingCreateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a booking
         * @param {string} bookingUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBooking(bookingUuid: string, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.deleteBooking(bookingUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of all bookings
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBookings(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseBookingDetail> {
            return localVarFp.getAllBookings(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of open bookings for the current user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenBookings(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseBookingSummary> {
            return localVarFp.getOpenBookings(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single booking by UUID
         * @param {string} bookingUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleBookingByUuid(bookingUuid: string, options?: any): AxiosPromise<ResponseBookingDetail> {
            return localVarFp.getSingleBookingByUuid(bookingUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a booking
         * @param {string} bookingUuid 
         * @param {BookingUpdateForm} bookingUpdateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(bookingUuid: string, bookingUpdateForm: BookingUpdateForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.updateBooking(bookingUuid, bookingUpdateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a booking reservation by booking UUID
         * @param {string} bookingUuid 
         * @param {BookingReservationUpdateForm} bookingReservationUpdateForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleBookingReservationByUuid(bookingUuid: string, bookingReservationUpdateForm: BookingReservationUpdateForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.updateSingleBookingReservationByUuid(bookingUuid, bookingReservationUpdateForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingControllerApi - object-oriented interface
 * @export
 * @class BookingControllerApi
 * @extends {BaseAPI}
 */
export class BookingControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a new booking
     * @param {BookingCreateForm} bookingCreateForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public createBooking(bookingCreateForm: BookingCreateForm, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).createBooking(bookingCreateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a booking
     * @param {string} bookingUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public deleteBooking(bookingUuid: string, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).deleteBooking(bookingUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of all bookings
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public getAllBookings(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).getAllBookings(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of open bookings for the current user
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public getOpenBookings(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).getOpenBookings(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single booking by UUID
     * @param {string} bookingUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public getSingleBookingByUuid(bookingUuid: string, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).getSingleBookingByUuid(bookingUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a booking
     * @param {string} bookingUuid 
     * @param {BookingUpdateForm} bookingUpdateForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public updateBooking(bookingUuid: string, bookingUpdateForm: BookingUpdateForm, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).updateBooking(bookingUuid, bookingUpdateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a booking reservation by booking UUID
     * @param {string} bookingUuid 
     * @param {BookingReservationUpdateForm} bookingReservationUpdateForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public updateSingleBookingReservationByUuid(bookingUuid: string, bookingReservationUpdateForm: BookingReservationUpdateForm, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).updateSingleBookingReservationByUuid(bookingUuid, bookingReservationUpdateForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingReservationControllerApi - axios parameter creator
 * @export
 */
export const BookingReservationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add samples to a MX data collection.
         * @param {string} bookingReservationUuid 
         * @param {BookingReservationAddSampleForm} bookingReservationAddSampleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSamples: async (bookingReservationUuid: string, bookingReservationAddSampleForm: BookingReservationAddSampleForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationUuid' is not null or undefined
            assertParamExists('addSamples', 'bookingReservationUuid', bookingReservationUuid)
            // verify required parameter 'bookingReservationAddSampleForm' is not null or undefined
            assertParamExists('addSamples', 'bookingReservationAddSampleForm', bookingReservationAddSampleForm)
            const localVarPath = `/api/v2/booking-reservation/{bookingReservationUuid}/samples`
                .replace(`{${"bookingReservationUuid"}}`, encodeURIComponent(String(bookingReservationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingReservationAddSampleForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new booking reservation for the current user\'s client
         * @param {BookingReservationForm} bookingReservationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingReservationForCurrentUser: async (bookingReservationForm: BookingReservationForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationForm' is not null or undefined
            assertParamExists('createBookingReservationForCurrentUser', 'bookingReservationForm', bookingReservationForm)
            const localVarPath = `/api/v2/booking-reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingReservationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single booking reservation by uuid.
         * @param {string} bookingReservationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReservation: async (bookingReservationUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationUuid' is not null or undefined
            assertParamExists('getBookingReservation', 'bookingReservationUuid', bookingReservationUuid)
            const localVarPath = `/api/v2/booking-reservation/{bookingReservationUuid}`
                .replace(`{${"bookingReservationUuid"}}`, encodeURIComponent(String(bookingReservationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single booking reservation by the MX data collection uuid.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReservationByMxDataCollectionUuid: async (mxDataCollectionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mxDataCollectionUuid' is not null or undefined
            assertParamExists('getBookingReservationByMxDataCollectionUuid', 'mxDataCollectionUuid', mxDataCollectionUuid)
            const localVarPath = `/api/v2/booking-reservation/mx-data-collection/{mxDataCollectionUuid}`
                .replace(`{${"mxDataCollectionUuid"}}`, encodeURIComponent(String(mxDataCollectionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a full list of booking reservations for a specific client
         * @param {string} clientUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReservationsByClient: async (clientUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUuid' is not null or undefined
            assertParamExists('getBookingReservationsByClient', 'clientUuid', clientUuid)
            const localVarPath = `/api/v2/booking-reservation/client/{clientUuid}`
                .replace(`{${"clientUuid"}}`, encodeURIComponent(String(clientUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove samples from a MX data collection.
         * @param {string} bookingReservationUuid 
         * @param {BookingReservationRemoveSampleForm} bookingReservationRemoveSampleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSamples: async (bookingReservationUuid: string, bookingReservationRemoveSampleForm: BookingReservationRemoveSampleForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationUuid' is not null or undefined
            assertParamExists('removeSamples', 'bookingReservationUuid', bookingReservationUuid)
            // verify required parameter 'bookingReservationRemoveSampleForm' is not null or undefined
            assertParamExists('removeSamples', 'bookingReservationRemoveSampleForm', bookingReservationRemoveSampleForm)
            const localVarPath = `/api/v2/booking-reservation/{bookingReservationUuid}/samples`
                .replace(`{${"bookingReservationUuid"}}`, encodeURIComponent(String(bookingReservationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingReservationRemoveSampleForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingReservationControllerApi - functional programming interface
 * @export
 */
export const BookingReservationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingReservationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add samples to a MX data collection.
         * @param {string} bookingReservationUuid 
         * @param {BookingReservationAddSampleForm} bookingReservationAddSampleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSamples(bookingReservationUuid: string, bookingReservationAddSampleForm: BookingReservationAddSampleForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSamples(bookingReservationUuid, bookingReservationAddSampleForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new booking reservation for the current user\'s client
         * @param {BookingReservationForm} bookingReservationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBookingReservationForCurrentUser(bookingReservationForm: BookingReservationForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBookingReservationForCurrentUser(bookingReservationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single booking reservation by uuid.
         * @param {string} bookingReservationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingReservation(bookingReservationUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBookingReservationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingReservation(bookingReservationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single booking reservation by the MX data collection uuid.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingReservationByMxDataCollectionUuid(mxDataCollectionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBookingReservationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingReservationByMxDataCollectionUuid(mxDataCollectionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a full list of booking reservations for a specific client
         * @param {string} clientUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingReservationsByClient(clientUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseBookingReservationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingReservationsByClient(clientUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove samples from a MX data collection.
         * @param {string} bookingReservationUuid 
         * @param {BookingReservationRemoveSampleForm} bookingReservationRemoveSampleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSamples(bookingReservationUuid: string, bookingReservationRemoveSampleForm: BookingReservationRemoveSampleForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSamples(bookingReservationUuid, bookingReservationRemoveSampleForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingReservationControllerApi - factory interface
 * @export
 */
export const BookingReservationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingReservationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add samples to a MX data collection.
         * @param {string} bookingReservationUuid 
         * @param {BookingReservationAddSampleForm} bookingReservationAddSampleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSamples(bookingReservationUuid: string, bookingReservationAddSampleForm: BookingReservationAddSampleForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.addSamples(bookingReservationUuid, bookingReservationAddSampleForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new booking reservation for the current user\'s client
         * @param {BookingReservationForm} bookingReservationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingReservationForCurrentUser(bookingReservationForm: BookingReservationForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.createBookingReservationForCurrentUser(bookingReservationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single booking reservation by uuid.
         * @param {string} bookingReservationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReservation(bookingReservationUuid: string, options?: any): AxiosPromise<ResponseBookingReservationSummary> {
            return localVarFp.getBookingReservation(bookingReservationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single booking reservation by the MX data collection uuid.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReservationByMxDataCollectionUuid(mxDataCollectionUuid: string, options?: any): AxiosPromise<ResponseBookingReservationSummary> {
            return localVarFp.getBookingReservationByMxDataCollectionUuid(mxDataCollectionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a full list of booking reservations for a specific client
         * @param {string} clientUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReservationsByClient(clientUuid: string, options?: any): AxiosPromise<ListResponseBookingReservationSummary> {
            return localVarFp.getBookingReservationsByClient(clientUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove samples from a MX data collection.
         * @param {string} bookingReservationUuid 
         * @param {BookingReservationRemoveSampleForm} bookingReservationRemoveSampleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSamples(bookingReservationUuid: string, bookingReservationRemoveSampleForm: BookingReservationRemoveSampleForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.removeSamples(bookingReservationUuid, bookingReservationRemoveSampleForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingReservationControllerApi - object-oriented interface
 * @export
 * @class BookingReservationControllerApi
 * @extends {BaseAPI}
 */
export class BookingReservationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add samples to a MX data collection.
     * @param {string} bookingReservationUuid 
     * @param {BookingReservationAddSampleForm} bookingReservationAddSampleForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingReservationControllerApi
     */
    public addSamples(bookingReservationUuid: string, bookingReservationAddSampleForm: BookingReservationAddSampleForm, options?: AxiosRequestConfig) {
        return BookingReservationControllerApiFp(this.configuration).addSamples(bookingReservationUuid, bookingReservationAddSampleForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new booking reservation for the current user\'s client
     * @param {BookingReservationForm} bookingReservationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingReservationControllerApi
     */
    public createBookingReservationForCurrentUser(bookingReservationForm: BookingReservationForm, options?: AxiosRequestConfig) {
        return BookingReservationControllerApiFp(this.configuration).createBookingReservationForCurrentUser(bookingReservationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single booking reservation by uuid.
     * @param {string} bookingReservationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingReservationControllerApi
     */
    public getBookingReservation(bookingReservationUuid: string, options?: AxiosRequestConfig) {
        return BookingReservationControllerApiFp(this.configuration).getBookingReservation(bookingReservationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single booking reservation by the MX data collection uuid.
     * @param {string} mxDataCollectionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingReservationControllerApi
     */
    public getBookingReservationByMxDataCollectionUuid(mxDataCollectionUuid: string, options?: AxiosRequestConfig) {
        return BookingReservationControllerApiFp(this.configuration).getBookingReservationByMxDataCollectionUuid(mxDataCollectionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a full list of booking reservations for a specific client
     * @param {string} clientUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingReservationControllerApi
     */
    public getBookingReservationsByClient(clientUuid: string, options?: AxiosRequestConfig) {
        return BookingReservationControllerApiFp(this.configuration).getBookingReservationsByClient(clientUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove samples from a MX data collection.
     * @param {string} bookingReservationUuid 
     * @param {BookingReservationRemoveSampleForm} bookingReservationRemoveSampleForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingReservationControllerApi
     */
    public removeSamples(bookingReservationUuid: string, bookingReservationRemoveSampleForm: BookingReservationRemoveSampleForm, options?: AxiosRequestConfig) {
        return BookingReservationControllerApiFp(this.configuration).removeSamples(bookingReservationUuid, bookingReservationRemoveSampleForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientControllerApi - axios parameter creator
 * @export
 */
export const ClientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a full list of all clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientControllerApi - functional programming interface
 * @export
 */
export const ClientControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseClientSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientControllerApi - factory interface
 * @export
 */
export const ClientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients(options?: any): AxiosPromise<ListResponseClientSummary> {
            return localVarFp.getAllClients(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientControllerApi - object-oriented interface
 * @export
 * @class ClientControllerApi
 * @extends {BaseAPI}
 */
export class ClientControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a full list of all clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientControllerApi
     */
    public getAllClients(options?: AxiosRequestConfig) {
        return ClientControllerApiFp(this.configuration).getAllClients(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompoundControllerApi - axios parameter creator
 * @export
 */
export const CompoundControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new compound
         * @param {CompoundForm} compoundForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompound: async (compoundForm: CompoundForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compoundForm' is not null or undefined
            assertParamExists('createCompound', 'compoundForm', compoundForm)
            const localVarPath = `/api/v2/compound`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compoundForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a compound
         * @param {string} compoundUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompound: async (compoundUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compoundUuid' is not null or undefined
            assertParamExists('deleteCompound', 'compoundUuid', compoundUuid)
            const localVarPath = `/api/v2/compound/{compoundUuid}`
                .replace(`{${"compoundUuid"}}`, encodeURIComponent(String(compoundUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of all compounds.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompounds: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/compound`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a compound
         * @param {string} compoundUuid 
         * @param {CompoundForm} compoundForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompound: async (compoundUuid: string, compoundForm: CompoundForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compoundUuid' is not null or undefined
            assertParamExists('updateCompound', 'compoundUuid', compoundUuid)
            // verify required parameter 'compoundForm' is not null or undefined
            assertParamExists('updateCompound', 'compoundForm', compoundForm)
            const localVarPath = `/api/v2/compound/{compoundUuid}`
                .replace(`{${"compoundUuid"}}`, encodeURIComponent(String(compoundUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compoundForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompoundControllerApi - functional programming interface
 * @export
 */
export const CompoundControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompoundControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new compound
         * @param {CompoundForm} compoundForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompound(compoundForm: CompoundForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompound(compoundForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a compound
         * @param {string} compoundUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompound(compoundUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompound(compoundUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of all compounds.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompounds(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseCompoundSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompounds(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a compound
         * @param {string} compoundUuid 
         * @param {CompoundForm} compoundForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompound(compoundUuid: string, compoundForm: CompoundForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompound(compoundUuid, compoundForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompoundControllerApi - factory interface
 * @export
 */
export const CompoundControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompoundControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new compound
         * @param {CompoundForm} compoundForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompound(compoundForm: CompoundForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.createCompound(compoundForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a compound
         * @param {string} compoundUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompound(compoundUuid: string, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.deleteCompound(compoundUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of all compounds.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompounds(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseCompoundSummary> {
            return localVarFp.getAllCompounds(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a compound
         * @param {string} compoundUuid 
         * @param {CompoundForm} compoundForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompound(compoundUuid: string, compoundForm: CompoundForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.updateCompound(compoundUuid, compoundForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompoundControllerApi - object-oriented interface
 * @export
 * @class CompoundControllerApi
 * @extends {BaseAPI}
 */
export class CompoundControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a new compound
     * @param {CompoundForm} compoundForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundControllerApi
     */
    public createCompound(compoundForm: CompoundForm, options?: AxiosRequestConfig) {
        return CompoundControllerApiFp(this.configuration).createCompound(compoundForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a compound
     * @param {string} compoundUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundControllerApi
     */
    public deleteCompound(compoundUuid: string, options?: AxiosRequestConfig) {
        return CompoundControllerApiFp(this.configuration).deleteCompound(compoundUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of all compounds.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundControllerApi
     */
    public getAllCompounds(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CompoundControllerApiFp(this.configuration).getAllCompounds(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a compound
     * @param {string} compoundUuid 
     * @param {CompoundForm} compoundForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundControllerApi
     */
    public updateCompound(compoundUuid: string, compoundForm: CompoundForm, options?: AxiosRequestConfig) {
        return CompoundControllerApiFp(this.configuration).updateCompound(compoundUuid, compoundForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetControllerApi - axios parameter creator
 * @export
 */
export const DatasetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all datasets by sample uuid.
         * @param {string} sampleUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetsBySampleUuid: async (sampleUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleUuid' is not null or undefined
            assertParamExists('getAllDatasetsBySampleUuid', 'sampleUuid', sampleUuid)
            const localVarPath = `/api/v2/dataset/sample/{sampleUuid}`
                .replace(`{${"sampleUuid"}}`, encodeURIComponent(String(sampleUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single dataset by uuid.
         * @param {string} datasetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleDatasetByUuid: async (datasetUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetUuid' is not null or undefined
            assertParamExists('getSingleDatasetByUuid', 'datasetUuid', datasetUuid)
            const localVarPath = `/api/v2/dataset/{datasetUuid}`
                .replace(`{${"datasetUuid"}}`, encodeURIComponent(String(datasetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetControllerApi - functional programming interface
 * @export
 */
export const DatasetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all datasets by sample uuid.
         * @param {string} sampleUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDatasetsBySampleUuid(sampleUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseDatasetSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDatasetsBySampleUuid(sampleUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single dataset by uuid.
         * @param {string} datasetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleDatasetByUuid(datasetUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDatasetSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleDatasetByUuid(datasetUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetControllerApi - factory interface
 * @export
 */
export const DatasetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all datasets by sample uuid.
         * @param {string} sampleUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetsBySampleUuid(sampleUuid: string, options?: any): AxiosPromise<ListResponseDatasetSummary> {
            return localVarFp.getAllDatasetsBySampleUuid(sampleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single dataset by uuid.
         * @param {string} datasetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleDatasetByUuid(datasetUuid: string, options?: any): AxiosPromise<ResponseDatasetSummary> {
            return localVarFp.getSingleDatasetByUuid(datasetUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetControllerApi - object-oriented interface
 * @export
 * @class DatasetControllerApi
 * @extends {BaseAPI}
 */
export class DatasetControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all datasets by sample uuid.
     * @param {string} sampleUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetControllerApi
     */
    public getAllDatasetsBySampleUuid(sampleUuid: string, options?: AxiosRequestConfig) {
        return DatasetControllerApiFp(this.configuration).getAllDatasetsBySampleUuid(sampleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single dataset by uuid.
     * @param {string} datasetUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetControllerApi
     */
    public getSingleDatasetByUuid(datasetUuid: string, options?: AxiosRequestConfig) {
        return DatasetControllerApiFp(this.configuration).getSingleDatasetByUuid(datasetUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload a file
         * @param {string} purpose 
         * @param {string} type 
         * @param {string} [collection] 
         * @param {UploadFileRequest} [uploadFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (purpose: string, type: string, collection?: string, uploadFileRequest?: UploadFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purpose' is not null or undefined
            assertParamExists('uploadFile', 'purpose', purpose)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadFile', 'type', type)
            const localVarPath = `/api/v2/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (collection !== undefined) {
                localVarQueryParameter['collection'] = collection;
            }

            if (purpose !== undefined) {
                localVarQueryParameter['purpose'] = purpose;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload a file
         * @param {string} purpose 
         * @param {string} type 
         * @param {string} [collection] 
         * @param {UploadFileRequest} [uploadFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(purpose: string, type: string, collection?: string, uploadFileRequest?: UploadFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(purpose, type, collection, uploadFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload a file
         * @param {string} purpose 
         * @param {string} type 
         * @param {string} [collection] 
         * @param {UploadFileRequest} [uploadFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(purpose: string, type: string, collection?: string, uploadFileRequest?: UploadFileRequest, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.uploadFile(purpose, type, collection, uploadFileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
    /**
     * 
     * @summary Upload a file
     * @param {string} purpose 
     * @param {string} type 
     * @param {string} [collection] 
     * @param {UploadFileRequest} [uploadFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public uploadFile(purpose: string, type: string, collection?: string, uploadFileRequest?: UploadFileRequest, options?: AxiosRequestConfig) {
        return FileControllerApiFp(this.configuration).uploadFile(purpose, type, collection, uploadFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobControllerApi - axios parameter creator
 * @export
 */
export const JobControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all jobs by job type and dataset UUID
         * @param {'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD'} jobType 
         * @param {string} datasetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsByDatasetAndType: async (jobType: 'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD', datasetUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('getAllJobsByDatasetAndType', 'jobType', jobType)
            // verify required parameter 'datasetUuid' is not null or undefined
            assertParamExists('getAllJobsByDatasetAndType', 'datasetUuid', datasetUuid)
            const localVarPath = `/api/v2/job/{jobType}/{datasetUuid}`
                .replace(`{${"jobType"}}`, encodeURIComponent(String(jobType)))
                .replace(`{${"datasetUuid"}}`, encodeURIComponent(String(datasetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single job by UUID
         * @param {string} jobUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleJobByUuid: async (jobUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobUuid' is not null or undefined
            assertParamExists('getSingleJobByUuid', 'jobUuid', jobUuid)
            const localVarPath = `/api/v2/job/{jobUuid}`
                .replace(`{${"jobUuid"}}`, encodeURIComponent(String(jobUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobControllerApi - functional programming interface
 * @export
 */
export const JobControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all jobs by job type and dataset UUID
         * @param {'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD'} jobType 
         * @param {string} datasetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllJobsByDatasetAndType(jobType: 'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD', datasetUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseJobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllJobsByDatasetAndType(jobType, datasetUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single job by UUID
         * @param {string} jobUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleJobByUuid(jobUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseJobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleJobByUuid(jobUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobControllerApi - factory interface
 * @export
 */
export const JobControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all jobs by job type and dataset UUID
         * @param {'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD'} jobType 
         * @param {string} datasetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsByDatasetAndType(jobType: 'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD', datasetUuid: string, options?: any): AxiosPromise<ListResponseJobDetail> {
            return localVarFp.getAllJobsByDatasetAndType(jobType, datasetUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single job by UUID
         * @param {string} jobUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleJobByUuid(jobUuid: string, options?: any): AxiosPromise<ResponseJobDetail> {
            return localVarFp.getSingleJobByUuid(jobUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobControllerApi - object-oriented interface
 * @export
 * @class JobControllerApi
 * @extends {BaseAPI}
 */
export class JobControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all jobs by job type and dataset UUID
     * @param {'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD'} jobType 
     * @param {string} datasetUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobControllerApi
     */
    public getAllJobsByDatasetAndType(jobType: 'XRAY' | 'MR' | 'REFINEMENT' | 'AUTOBUILD', datasetUuid: string, options?: AxiosRequestConfig) {
        return JobControllerApiFp(this.configuration).getAllJobsByDatasetAndType(jobType, datasetUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single job by UUID
     * @param {string} jobUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobControllerApi
     */
    public getSingleJobByUuid(jobUuid: string, options?: AxiosRequestConfig) {
        return JobControllerApiFp(this.configuration).getSingleJobByUuid(jobUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MxDataCollectionControllerApi - axios parameter creator
 * @export
 */
export const MxDataCollectionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Administrator cancelling a MX data collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancel: async (mxDataCollectionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mxDataCollectionUuid' is not null or undefined
            assertParamExists('adminCancel', 'mxDataCollectionUuid', mxDataCollectionUuid)
            const localVarPath = `/api/v2/mx-data-collection/{mxDataCollectionUuid}/admin-cancel`
                .replace(`{${"mxDataCollectionUuid"}}`, encodeURIComponent(String(mxDataCollectionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Client cancelling a MX data collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCancel: async (mxDataCollectionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mxDataCollectionUuid' is not null or undefined
            assertParamExists('clientCancel', 'mxDataCollectionUuid', mxDataCollectionUuid)
            const localVarPath = `/api/v2/mx-data-collection/{mxDataCollectionUuid}/client-cancel`
                .replace(`{${"mxDataCollectionUuid"}}`, encodeURIComponent(String(mxDataCollectionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of future MX data collections for the current user\'s client
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFutureMxDataCollectionsForCurrentUser: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/mx-data-collection/client/future`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of MX data collections for the current user\'s client
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMxDataCollectionsForCurrentUser: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/mx-data-collection/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single MX data collection by booking reservation UUID
         * @param {string} bookingReservationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMxDataCollectionByBookingReservationUuid: async (bookingReservationUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationUuid' is not null or undefined
            assertParamExists('getMxDataCollectionByBookingReservationUuid', 'bookingReservationUuid', bookingReservationUuid)
            const localVarPath = `/api/v2/mx-data-collection/booking-reservation/{bookingReservationUuid}`
                .replace(`{${"bookingReservationUuid"}}`, encodeURIComponent(String(bookingReservationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single MX data collection by UUID
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMxDataCollectionByUuid: async (mxDataCollectionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mxDataCollectionUuid' is not null or undefined
            assertParamExists('getMxDataCollectionByUuid', 'mxDataCollectionUuid', mxDataCollectionUuid)
            const localVarPath = `/api/v2/mx-data-collection/{mxDataCollectionUuid}`
                .replace(`{${"mxDataCollectionUuid"}}`, encodeURIComponent(String(mxDataCollectionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MxDataCollectionControllerApi - functional programming interface
 * @export
 */
export const MxDataCollectionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MxDataCollectionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Administrator cancelling a MX data collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancel(mxDataCollectionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancel(mxDataCollectionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Client cancelling a MX data collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCancel(mxDataCollectionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCancel(mxDataCollectionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of future MX data collections for the current user\'s client
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFutureMxDataCollectionsForCurrentUser(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseMxDataCollectionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFutureMxDataCollectionsForCurrentUser(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of MX data collections for the current user\'s client
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMxDataCollectionsForCurrentUser(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseMxDataCollectionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMxDataCollectionsForCurrentUser(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single MX data collection by booking reservation UUID
         * @param {string} bookingReservationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMxDataCollectionByBookingReservationUuid(bookingReservationUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMxDataCollectionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMxDataCollectionByBookingReservationUuid(bookingReservationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single MX data collection by UUID
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMxDataCollectionByUuid(mxDataCollectionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMxDataCollectionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMxDataCollectionByUuid(mxDataCollectionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MxDataCollectionControllerApi - factory interface
 * @export
 */
export const MxDataCollectionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MxDataCollectionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Administrator cancelling a MX data collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancel(mxDataCollectionUuid: string, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.adminCancel(mxDataCollectionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Client cancelling a MX data collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCancel(mxDataCollectionUuid: string, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.clientCancel(mxDataCollectionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of future MX data collections for the current user\'s client
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFutureMxDataCollectionsForCurrentUser(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseMxDataCollectionSummary> {
            return localVarFp.getAllFutureMxDataCollectionsForCurrentUser(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of MX data collections for the current user\'s client
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMxDataCollectionsForCurrentUser(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseMxDataCollectionSummary> {
            return localVarFp.getAllMxDataCollectionsForCurrentUser(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single MX data collection by booking reservation UUID
         * @param {string} bookingReservationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMxDataCollectionByBookingReservationUuid(bookingReservationUuid: string, options?: any): AxiosPromise<ResponseMxDataCollectionSummary> {
            return localVarFp.getMxDataCollectionByBookingReservationUuid(bookingReservationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single MX data collection by UUID
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMxDataCollectionByUuid(mxDataCollectionUuid: string, options?: any): AxiosPromise<ResponseMxDataCollectionSummary> {
            return localVarFp.getMxDataCollectionByUuid(mxDataCollectionUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MxDataCollectionControllerApi - object-oriented interface
 * @export
 * @class MxDataCollectionControllerApi
 * @extends {BaseAPI}
 */
export class MxDataCollectionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Administrator cancelling a MX data collection.
     * @param {string} mxDataCollectionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxDataCollectionControllerApi
     */
    public adminCancel(mxDataCollectionUuid: string, options?: AxiosRequestConfig) {
        return MxDataCollectionControllerApiFp(this.configuration).adminCancel(mxDataCollectionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Client cancelling a MX data collection.
     * @param {string} mxDataCollectionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxDataCollectionControllerApi
     */
    public clientCancel(mxDataCollectionUuid: string, options?: AxiosRequestConfig) {
        return MxDataCollectionControllerApiFp(this.configuration).clientCancel(mxDataCollectionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of future MX data collections for the current user\'s client
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxDataCollectionControllerApi
     */
    public getAllFutureMxDataCollectionsForCurrentUser(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return MxDataCollectionControllerApiFp(this.configuration).getAllFutureMxDataCollectionsForCurrentUser(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of MX data collections for the current user\'s client
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxDataCollectionControllerApi
     */
    public getAllMxDataCollectionsForCurrentUser(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return MxDataCollectionControllerApiFp(this.configuration).getAllMxDataCollectionsForCurrentUser(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single MX data collection by booking reservation UUID
     * @param {string} bookingReservationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxDataCollectionControllerApi
     */
    public getMxDataCollectionByBookingReservationUuid(bookingReservationUuid: string, options?: AxiosRequestConfig) {
        return MxDataCollectionControllerApiFp(this.configuration).getMxDataCollectionByBookingReservationUuid(bookingReservationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single MX data collection by UUID
     * @param {string} mxDataCollectionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxDataCollectionControllerApi
     */
    public getMxDataCollectionByUuid(mxDataCollectionUuid: string, options?: AxiosRequestConfig) {
        return MxDataCollectionControllerApiFp(this.configuration).getMxDataCollectionByUuid(mxDataCollectionUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProteinControllerApi - axios parameter creator
 * @export
 */
export const ProteinControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new protein
         * @param {ProteinForm} proteinForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProtein: async (proteinForm: ProteinForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proteinForm' is not null or undefined
            assertParamExists('createProtein', 'proteinForm', proteinForm)
            const localVarPath = `/api/v2/protein`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proteinForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a protein
         * @param {string} proteinUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProtein: async (proteinUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proteinUuid' is not null or undefined
            assertParamExists('deleteProtein', 'proteinUuid', proteinUuid)
            const localVarPath = `/api/v2/protein/{proteinUuid}`
                .replace(`{${"proteinUuid"}}`, encodeURIComponent(String(proteinUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of all proteins
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProteins: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/protein`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a protein
         * @param {string} proteinUuid 
         * @param {ProteinForm} proteinForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProtein: async (proteinUuid: string, proteinForm: ProteinForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proteinUuid' is not null or undefined
            assertParamExists('updateProtein', 'proteinUuid', proteinUuid)
            // verify required parameter 'proteinForm' is not null or undefined
            assertParamExists('updateProtein', 'proteinForm', proteinForm)
            const localVarPath = `/api/v2/protein/{proteinUuid}`
                .replace(`{${"proteinUuid"}}`, encodeURIComponent(String(proteinUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proteinForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProteinControllerApi - functional programming interface
 * @export
 */
export const ProteinControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProteinControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new protein
         * @param {ProteinForm} proteinForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProtein(proteinForm: ProteinForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProtein(proteinForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a protein
         * @param {string} proteinUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProtein(proteinUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProtein(proteinUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of all proteins
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProteins(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProteinSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProteins(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a protein
         * @param {string} proteinUuid 
         * @param {ProteinForm} proteinForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProtein(proteinUuid: string, proteinForm: ProteinForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProtein(proteinUuid, proteinForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProteinControllerApi - factory interface
 * @export
 */
export const ProteinControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProteinControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new protein
         * @param {ProteinForm} proteinForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProtein(proteinForm: ProteinForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.createProtein(proteinForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a protein
         * @param {string} proteinUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProtein(proteinUuid: string, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.deleteProtein(proteinUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of all proteins
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProteins(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseProteinSummary> {
            return localVarFp.getAllProteins(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a protein
         * @param {string} proteinUuid 
         * @param {ProteinForm} proteinForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProtein(proteinUuid: string, proteinForm: ProteinForm, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.updateProtein(proteinUuid, proteinForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProteinControllerApi - object-oriented interface
 * @export
 * @class ProteinControllerApi
 * @extends {BaseAPI}
 */
export class ProteinControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a new protein
     * @param {ProteinForm} proteinForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProteinControllerApi
     */
    public createProtein(proteinForm: ProteinForm, options?: AxiosRequestConfig) {
        return ProteinControllerApiFp(this.configuration).createProtein(proteinForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a protein
     * @param {string} proteinUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProteinControllerApi
     */
    public deleteProtein(proteinUuid: string, options?: AxiosRequestConfig) {
        return ProteinControllerApiFp(this.configuration).deleteProtein(proteinUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of all proteins
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProteinControllerApi
     */
    public getAllProteins(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ProteinControllerApiFp(this.configuration).getAllProteins(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a protein
     * @param {string} proteinUuid 
     * @param {ProteinForm} proteinForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProteinControllerApi
     */
    public updateProtein(proteinUuid: string, proteinForm: ProteinForm, options?: AxiosRequestConfig) {
        return ProteinControllerApiFp(this.configuration).updateProtein(proteinUuid, proteinForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PuckTypeControllerApi - axios parameter creator
 * @export
 */
export const PuckTypeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a full list of all puck types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPuckTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/puck-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PuckTypeControllerApi - functional programming interface
 * @export
 */
export const PuckTypeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PuckTypeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all puck types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPuckTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponsePuckTypeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPuckTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PuckTypeControllerApi - factory interface
 * @export
 */
export const PuckTypeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PuckTypeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all puck types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPuckTypes(options?: any): AxiosPromise<ListResponsePuckTypeDetail> {
            return localVarFp.getAllPuckTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PuckTypeControllerApi - object-oriented interface
 * @export
 * @class PuckTypeControllerApi
 * @extends {BaseAPI}
 */
export class PuckTypeControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a full list of all puck types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PuckTypeControllerApi
     */
    public getAllPuckTypes(options?: AxiosRequestConfig) {
        return PuckTypeControllerApiFp(this.configuration).getAllPuckTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SampleControllerApi - axios parameter creator
 * @export
 */
export const SampleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a full list of all samples for a particular MX Data Collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleDetailsForMxDataCollection: async (mxDataCollectionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mxDataCollectionUuid' is not null or undefined
            assertParamExists('getSampleDetailsForMxDataCollection', 'mxDataCollectionUuid', mxDataCollectionUuid)
            const localVarPath = `/api/v2/sample/mx-data-collection/{mxDataCollectionUuid}`
                .replace(`{${"mxDataCollectionUuid"}}`, encodeURIComponent(String(mxDataCollectionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a full list of all samples entries for a particular MX Data Collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleEntryForMxDataCollection: async (mxDataCollectionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mxDataCollectionUuid' is not null or undefined
            assertParamExists('getSampleEntryForMxDataCollection', 'mxDataCollectionUuid', mxDataCollectionUuid)
            const localVarPath = `/api/v2/sample/mx-data-collection/{mxDataCollectionUuid}/data-entry`
                .replace(`{${"mxDataCollectionUuid"}}`, encodeURIComponent(String(mxDataCollectionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sample data
         * @param {SampleEntryDetail} sampleEntryDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleEntryData: async (sampleEntryDetail: SampleEntryDetail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleEntryDetail' is not null or undefined
            assertParamExists('updateSampleEntryData', 'sampleEntryDetail', sampleEntryDetail)
            const localVarPath = `/api/v2/sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sampleEntryDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SampleControllerApi - functional programming interface
 * @export
 */
export const SampleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SampleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all samples for a particular MX Data Collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSampleDetailsForMxDataCollection(mxDataCollectionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseSampleDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleDetailsForMxDataCollection(mxDataCollectionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a full list of all samples entries for a particular MX Data Collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSampleEntryForMxDataCollection(mxDataCollectionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseSampleEntryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleEntryForMxDataCollection(mxDataCollectionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update sample data
         * @param {SampleEntryDetail} sampleEntryDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSampleEntryData(sampleEntryDetail: SampleEntryDetail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSampleEntryData(sampleEntryDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SampleControllerApi - factory interface
 * @export
 */
export const SampleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SampleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all samples for a particular MX Data Collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleDetailsForMxDataCollection(mxDataCollectionUuid: string, options?: any): AxiosPromise<ListResponseSampleDetail> {
            return localVarFp.getSampleDetailsForMxDataCollection(mxDataCollectionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a full list of all samples entries for a particular MX Data Collection.
         * @param {string} mxDataCollectionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleEntryForMxDataCollection(mxDataCollectionUuid: string, options?: any): AxiosPromise<ListResponseSampleEntryDetail> {
            return localVarFp.getSampleEntryForMxDataCollection(mxDataCollectionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sample data
         * @param {SampleEntryDetail} sampleEntryDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleEntryData(sampleEntryDetail: SampleEntryDetail, options?: any): AxiosPromise<ResponseCreationResponse> {
            return localVarFp.updateSampleEntryData(sampleEntryDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SampleControllerApi - object-oriented interface
 * @export
 * @class SampleControllerApi
 * @extends {BaseAPI}
 */
export class SampleControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a full list of all samples for a particular MX Data Collection.
     * @param {string} mxDataCollectionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleControllerApi
     */
    public getSampleDetailsForMxDataCollection(mxDataCollectionUuid: string, options?: AxiosRequestConfig) {
        return SampleControllerApiFp(this.configuration).getSampleDetailsForMxDataCollection(mxDataCollectionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a full list of all samples entries for a particular MX Data Collection.
     * @param {string} mxDataCollectionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleControllerApi
     */
    public getSampleEntryForMxDataCollection(mxDataCollectionUuid: string, options?: AxiosRequestConfig) {
        return SampleControllerApiFp(this.configuration).getSampleEntryForMxDataCollection(mxDataCollectionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sample data
     * @param {SampleEntryDetail} sampleEntryDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleControllerApi
     */
    public updateSampleEntryData(sampleEntryDetail: SampleEntryDetail, options?: AxiosRequestConfig) {
        return SampleControllerApiFp(this.configuration).updateSampleEntryData(sampleEntryDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippingDocumentControllerApi - axios parameter creator
 * @export
 */
export const ShippingDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of shipping documents.
         * @param {string} bookingReservationUuid 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingDocuments: async (bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationUuid' is not null or undefined
            assertParamExists('getAllShippingDocuments', 'bookingReservationUuid', bookingReservationUuid)
            const localVarPath = `/api/v2/shipping-document/{bookingReservationUuid}`
                .replace(`{${"bookingReservationUuid"}}`, encodeURIComponent(String(bookingReservationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of shipping documents for use by administrators.
         * @param {string} bookingReservationUuid 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingDocumentsForAdmin: async (bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReservationUuid' is not null or undefined
            assertParamExists('getAllShippingDocumentsForAdmin', 'bookingReservationUuid', bookingReservationUuid)
            const localVarPath = `/api/v2/shipping-document/{bookingReservationUuid}/admin`
                .replace(`{${"bookingReservationUuid"}}`, encodeURIComponent(String(bookingReservationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingDocumentControllerApi - functional programming interface
 * @export
 */
export const ShippingDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a paginated list of shipping documents.
         * @param {string} bookingReservationUuid 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShippingDocuments(bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseShippingDocumentSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShippingDocuments(bookingReservationUuid, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of shipping documents for use by administrators.
         * @param {string} bookingReservationUuid 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShippingDocumentsForAdmin(bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseShippingDocumentSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShippingDocumentsForAdmin(bookingReservationUuid, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippingDocumentControllerApi - factory interface
 * @export
 */
export const ShippingDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a paginated list of shipping documents.
         * @param {string} bookingReservationUuid 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingDocuments(bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseShippingDocumentSummary> {
            return localVarFp.getAllShippingDocuments(bookingReservationUuid, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of shipping documents for use by administrators.
         * @param {string} bookingReservationUuid 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingDocumentsForAdmin(bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseShippingDocumentSummary> {
            return localVarFp.getAllShippingDocumentsForAdmin(bookingReservationUuid, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingDocumentControllerApi - object-oriented interface
 * @export
 * @class ShippingDocumentControllerApi
 * @extends {BaseAPI}
 */
export class ShippingDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of shipping documents.
     * @param {string} bookingReservationUuid 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingDocumentControllerApi
     */
    public getAllShippingDocuments(bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ShippingDocumentControllerApiFp(this.configuration).getAllShippingDocuments(bookingReservationUuid, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of shipping documents for use by administrators.
     * @param {string} bookingReservationUuid 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingDocumentControllerApi
     */
    public getAllShippingDocumentsForAdmin(bookingReservationUuid: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ShippingDocumentControllerApiFp(this.configuration).getAllShippingDocumentsForAdmin(bookingReservationUuid, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SynchrotronControllerApi - axios parameter creator
 * @export
 */
export const SynchrotronControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of beamlines by synchrotron code
         * @param {string} code 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBeamlinesBySynchrotronCode: async (code: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getAllBeamlinesBySynchrotronCode', 'code', code)
            const localVarPath = `/api/v2/synchrotron/{code}/beamline`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of synchrotrons
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSynchrotrons: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/synchrotron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single synchrotron by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleSynchrotronByCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getSingleSynchrotronByCode', 'code', code)
            const localVarPath = `/api/v2/synchrotron/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchrotronControllerApi - functional programming interface
 * @export
 */
export const SynchrotronControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SynchrotronControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a paginated list of beamlines by synchrotron code
         * @param {string} code 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBeamlinesBySynchrotronCode(code: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseBeamlineSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBeamlinesBySynchrotronCode(code, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of synchrotrons
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSynchrotrons(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseSynchrotronSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSynchrotrons(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single synchrotron by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleSynchrotronByCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSynchrotronSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleSynchrotronByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SynchrotronControllerApi - factory interface
 * @export
 */
export const SynchrotronControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SynchrotronControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a paginated list of beamlines by synchrotron code
         * @param {string} code 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBeamlinesBySynchrotronCode(code: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseBeamlineSummary> {
            return localVarFp.getAllBeamlinesBySynchrotronCode(code, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of synchrotrons
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSynchrotrons(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginatedResponseSynchrotronSummary> {
            return localVarFp.getAllSynchrotrons(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single synchrotron by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleSynchrotronByCode(code: string, options?: any): AxiosPromise<ResponseSynchrotronSummary> {
            return localVarFp.getSingleSynchrotronByCode(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SynchrotronControllerApi - object-oriented interface
 * @export
 * @class SynchrotronControllerApi
 * @extends {BaseAPI}
 */
export class SynchrotronControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of beamlines by synchrotron code
     * @param {string} code 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchrotronControllerApi
     */
    public getAllBeamlinesBySynchrotronCode(code: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return SynchrotronControllerApiFp(this.configuration).getAllBeamlinesBySynchrotronCode(code, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of synchrotrons
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchrotronControllerApi
     */
    public getAllSynchrotrons(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return SynchrotronControllerApiFp(this.configuration).getAllSynchrotrons(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single synchrotron by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchrotronControllerApi
     */
    public getSingleSynchrotronByCode(code: string, options?: AxiosRequestConfig) {
        return SynchrotronControllerApiFp(this.configuration).getSingleSynchrotronByCode(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/user/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<ResponseUserSummary> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionControllerApi - axios parameter creator
 * @export
 */
export const VersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionControllerApi - functional programming interface
 * @export
 */
export const VersionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionControllerApi - factory interface
 * @export
 */
export const VersionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<ResponseString> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionControllerApi - object-oriented interface
 * @export
 * @class VersionControllerApi
 * @extends {BaseAPI}
 */
export class VersionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowControllerApi - axios parameter creator
 * @export
 */
export const WorkflowControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a full list of all workflows for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkflows: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowControllerApi - functional programming interface
 * @export
 */
export const WorkflowControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all workflows for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkflows(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseWorkflowSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkflows(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowControllerApi - factory interface
 * @export
 */
export const WorkflowControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a full list of all workflows for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkflows(options?: any): AxiosPromise<ListResponseWorkflowSummary> {
            return localVarFp.getAllWorkflows(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowControllerApi - object-oriented interface
 * @export
 * @class WorkflowControllerApi
 * @extends {BaseAPI}
 */
export class WorkflowControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a full list of all workflows for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public getAllWorkflows(options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).getAllWorkflows(options).then((request) => request(this.axios, this.basePath));
    }
}


