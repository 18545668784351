import Link from 'next/link';
import React from 'react';

interface BreadcrumbItemProps {
    breadcrumb: string;
    href: string;
    index: number;
    numberOfBreadcrumbs: number;
}

const labelTransform = (label: string) => {
    switch (label.toLowerCase()) {
        case 'cryo-em':
            return 'Cryo-EM';
        case 'mx':
            return 'MX';
        case 'saxs':
            return 'SAXS';
    }

    const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);

    return capitalizedLabel.replace(/\?.*/, '');
};

const BreadcrumbItem = (props: BreadcrumbItemProps) => {
    if (props.numberOfBreadcrumbs === 0 || labelTransform(props.breadcrumb).length === 0) {
        return null;
    }

    if (props.index === props.numberOfBreadcrumbs - 1) {
        return (
            <>
                <li className="ml-2 inline text-lg">&raquo;</li>
                <li key={props.href} className="ml-2 inline">
                    {labelTransform(props.breadcrumb)}
                </li>
            </>
        );
    }

    return (
        <>
            <li className="ml-2 inline text-lg">&raquo;</li>
            <li key={props.href} className="ml-2 inline hover:underline">
                <Link href={props.href}>{labelTransform(props.breadcrumb)}</Link>
            </li>
        </>
    );
};

export default BreadcrumbItem;
